<template>
  <div class="mbanner mbanner1 swiper-container">
    <ul class="swiper-wrapper">
      <li class="swiper-slide" v-for="item in pictureList" :key="item.id">
        <div class="mbanner-img">
          <img :src="item.pic" />
        </div>
      </li>
    </ul>
    <div class="mbanner-button">
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
  </div>
</template>
<script>
import Swiper from "@/libs/js/swiper.min";
export default {
  data() {
    return {
      pictureList: [],
    };
  },
  mounted() {
    this.getPictureList();
  },
  methods: {
    swiperInit() {
      new Swiper(".mbanner", {
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        speed: 600,
        autoplay: 4000,
        loop: true,
        autoplayDisableOnInteraction: false,
      });
    },

    getPictureList() {
      this.api
        .getPictureList({ pageNo: 1, pageSize: 50, type: 7 })
        .then((res) => {
          const {
            data: { result },
          } = res;
          const { records } = result || [];
          this.pictureList = records;
          setTimeout(() => {
            this.swiperInit();
          }, 300);
        });
    },
  },
};
</script>
<style scoped lang="scss">
.mbanner-img img {
  height: 100%;
}
@media screen and (max-width: 1201px) {
  .mbanner {
    height: 200px;
  }
  .mbanner-img img {
    height: 50%;
  }
  .mbanner .mbanner-button{
    height: 20px;
  }
  .mbanner-button .swiper-button-next{
   margin-left: 188px;
  }
  .mbanner-button .swiper-button-pre {
   margin-right: 10px;
  }
}
</style>
