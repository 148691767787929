<template>
  <div class="mrow mggzs">
    <div class="wrapper">
      <div class="mggzs-l">
        <ul>
          <li :class="activeTab == item.sort ? 'active' : ''"
              @click="toTaglet(index,item)"
              v-for="(item, index) in list"
              :key="item.id">
            <a href="javascript:;">
              <i class="icon">
                <img class="icon01"
                     :src="item.icon" />
                <img class="icon02"
                     :src="item.clickIcon" />
              </i>
              <h3>{{ item.name }}</h3>
            </a>
          </li>
        </ul>
      </div>
      <div class="mggzs-r">
        <div class="swiper-container">
          <ul class="swiper-wrapper">
            <li class="swiper-slide"
                v-for="item in photoList"
                :key="item.photo">
              <div class="img">
                <img :src="item.photo" />
              </div>
            </li>
          </ul>
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import $ from "@/libs/js/jquery-1.10.1.min";
// import Swiper from "@/libs/js/swiper.min";
import Swiper from 'swiper';
export default {
  data () {
    return {
      list: [],
      photoList: [],
      activeTab: 1,
      swiper: null,
    };
  },
  mounted () {
    this.initSwiper();
    this.getDisplayList();
  },
  methods: {
    initSwiper () {
      this.swiper = new Swiper(".mggzs-r .swiper-container", {
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        autoplay: 5000,
        loop: true,
        init: false,
        autoplayDisableOnInteraction: false,
      });
    },
    toTaglet (idex, item) {
      this.activeTab = item.sort;
      this.photoList = item.photoList


      this.$nextTick(() => {
        this.initSwiper();
        this.swiper.init();
      })

    },
    getDisplayList () {
      this.api.getDisplayList({ pageNo: 1, pageSize: 50 }).then((res) => {
        const {
          data: { result },
        } = res;
        const { records } = result || [];
        if (records.length == 0) return;
        this.list = records;
        this.photoList = this.list[0].photoList
        this.$nextTick(() => {
          // this.swiper.update();
          this.initSwiper();
          this.swiper.init();
        });
      });
    },
  },
};
</script>
<style scoped lang="scss">
.mimgs-tabbd li .img img {
  width: 868px;
  border-radius: 10px;
  display: block;
  transition: all 0.3s ease-in-out;
}
</style>
<style>
/* .mggzs .el-carousel__container,
.mggzs .el-carousel__container > div,
.mggzs .el-carousel__item {
  width: 100% !important;
  height: 100% !important;
}
.mggzs .el-carousel__indicators--outside {
  display: none;
}
.mggzs .el-carousel__arrow.el-carousel__arrow--left {
  position: absolute;
  right: 10px;
  bottom: 10px;
} */

.mggzs .el-carousel__arrow.el-carousel__arrow--right {
  position: absolute;
  right: 110px;
  bottom: 10px;
}


@media screen and (max-width: 1201px) {
    .mggzs-r {
      height: 188px;
    }
    .mggzs-r .swiper-button-prev{
      right: 88px;
      bottom: 14px;
      margin: 0 160px;
      top: 132px;
    }
    .mggzs-r .swiper-button-next{
      top: 132px;
    }
}
</style>
