<template>
  <div class="el_exhibition">
    <contents />
    <introduce />
  </div>
</template>

<script>
import contents from "./contents.vue";
import introduce from "./introduce.vue";
export default {
  data() {
    return {
      msg: "el_exhibition",
    };
  },
  components: {
    contents,
    introduce,
  },
  created() {},
  methods: {},
};
</script>
<style scoped lang="scss"></style>
